import React, { Component } from "react"
import { graphql } from "gatsby"

//components
import IndexLayout from "../components/IndexLayout"
import SEO from "../components/seo"

//utilities
import styled from "styled-components"
import { rhythm } from "../utils/typography"
import { themeMedia } from "../utils/theme"

const FormContainerStyled = styled.form`
  background-color: ${({ theme }) => theme.cardBg};
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-auto-rows: auto;
  grid-gap: 1em 1.5em;
  padding: ${rhythm(1 / 2)};
  box-shadow: ${({ theme }) => theme.baseBoxShadow};

  @media (max-width: ${themeMedia.maxXsmall}) {
    grid-template-columns: 1fr;
  }
`
const LabelStyled = styled.label`
  justify-self: end;

  @media (max-width: ${themeMedia.maxXsmall}) {
    justify-self: start;
  }
`
const EraseButtonStyled = styled.input`
  background-color: ${({ theme }) => theme.clearButtonBg};
  color: whitesmoke;
  border: none;
  border-radius: 1em;
  box-shadow: ${({ theme }) => theme.baseBoxShadow};

  & :hover {
    cursor: pointer;
  }

  & :active {
    box-shadow: none;
    transform: translateY(4px);
  }
`
const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.submitButtonBg};
  color: whitesmoke;
  border: none;
  border-radius: 1em;
  box-shadow: ${({ theme }) => theme.baseBoxShadow};

  & :hover {
    cursor: pointer;
  }

  & :active {
    box-shadow: none;
    transform: translateY(4px);
  }
`

class ContactForm extends Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <IndexLayout location={this.props.location} title={siteTitle}>
        <SEO title="Me Contacter" />
        <h2>Me Contacter</h2>
        <p>
          Vous souhaitez me contacter ? Que vous vouliez me soumettre une idée
          d'article, me poser une question ou bien faire un brin de causette,
          vous êtes au bon endroit. Remplissez ce formulaire et je vous
          répondrai aussi vite que possible.{" "}
        </p>
        <FormContainerStyled
          method="post"
          name="contact"
          netlify-honeypot="bot-field"
          data-netlify="true"
          action="/contact-success"
          className="card"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
          <LabelStyled htmlFor="name">Nom </LabelStyled>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Jon Snow"
            required={true}
          />

          <LabelStyled htmlFor="email">Email</LabelStyled>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="jon.snow@winterfell.wes"
            required={true}
          />

          <LabelStyled htmlFor="subject">Sujet </LabelStyled>
          <input
            type="text"
            name="subject"
            id="subject"
            placeholder="L'hiver arrive !"
            required={true}
          />

          <LabelStyled htmlFor="message">Message </LabelStyled>
          <textarea
            name="message"
            id="message"
            rows="5"
            placeholder="L'armée des morts est en marche !"
            required={true}
          />

          <EraseButtonStyled type="reset" value="Effacer" />
          <SubmitButton type="submit">Envoyer</SubmitButton>
        </FormContainerStyled>
      </IndexLayout>
    )
  }
}

export default ContactForm

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
